<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      slot="header"
      class="clearfix"
    >
      <span><strong>List of Approved Schools</strong></span>
      <!-- <el-button
        style="float: right; padding: 3px 0"
        type="text"
      >
        Register New
      </el-button> -->
    </div>
    <hr>
    <v-client-table
      v-model="schools"
      v-loading="load"
      :columns="columns"
      :options="options"
    >
      <div
        slot="logo"
        slot-scope="{row}"
      >
        <img
          :src="baseServerUrl +'storage/'+row.logo"
          width="100"
        >
      </div>
      <div
        slot="suspended_for_nonpayment"
        slot-scope="props"
      >
        <el-select
          v-model="props.row.suspended_for_nonpayment"
          style="width: 100%"
          @input="toggleSuspensionStatus(props, $event)"
        >
          <el-option
            label="Active"
            :value="0"
          />
          <el-option
            label="Suspended"
            :value="1"
          />
        </el-select>
      </div>
      <div
        slot="created_at"
        slot-scope="{row}"
      >{{ moment(row.created_at).format('MMM Do YYYY') }}
      </div>
      <div
        slot="action"
        slot-scope="props"
      >
        <span>
          <b-button
            v-b-tooltip.hover.right="'View Details'"
            variant="primary"
            class="btn-icon rounded-circle"
          >

            <router-link
              :to="{name: 'schoolDetails', params: {id: props.row.id}}"
              style="color: #fff;"
            ><feather-icon icon="EyeIcon" /></router-link>
          </b-button>
        </span>
        <!-- <span
          v-if="checkRole(['super'])"
        >
          <b-button
            v-b-tooltip.hover.right="'Reset Password'"
            variant="warning"
            class="btn-icon rounded-circle"
            @click="resetPassword(props.row.student.user)"
          >
            <feather-icon icon="UnlockIcon" />
          </b-button>
        </span> -->
        <span
          v-if="checkRole(['super'])"
        >
          <b-button
            v-b-tooltip.hover.right="'Login as admin of ' + props.row.name"
            variant="dark"
            class="btn-icon rounded-circle"
            @click="loginToASchool(props.row)"
          >
            <feather-icon icon="KeyIcon" />
          </b-button>
        </span>
      </div>
    </v-client-table>
  </div>
</template>
<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import checkRole from '@/utils/role'
import Resource from '@/api/resource'

export default {
  components: { BButton },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      schools: [],
      columns: [
        'action',
        'suspended_for_nonpayment',
        'id',
        'logo',
        'name',
        'email',
        'phone',
        'curriculum',
        'created_at',
      ],

      options: {
        headings: {
          suspended_for_nonpayment: 'Current Status',
          created_at: 'Date Confirmed',
          action: '',

          id: 'ID',
        },
        rowAttributesCallback(row) {
          if (row.suspended_for_nonpayment === 1) {
            return { style: 'background: #d83b3beb; color: #000000' }
          }
          return { style: 'background: #36c15ecf; color: #000000' }
        },
        sortable: [],
        // filterable: false,
        filterable: [],
      },
      load: false,
      selected_student_schools: [],
      mark_assignment: false,
    }
  },
  computed: {
    baseServerUrl() {
      return this.$store.getters.baseServerUrl
    },
  },
  created() {
    this.fetchSchools()
  },
  methods: {
    moment,
    checkRole,
    avatarText,
    fetchSchools() {
      const app = this
      app.load = true
      const fetchSchoolsResource = new Resource('schools')
      fetchSchoolsResource.list()
        .then(response => {
          app.schools = response.schools
          app.load = false
        }).catch(error => {
          app.$message(error.response.data.message)
          app.load = false
        })
    },
    async loginToASchool(school) {
      await this.$store.dispatch('user/loginToASchool', { school_id: school.id })
      // this.$router.push('/login').catch(() => {})
      window.location = '/'
    },
    toggleSuspensionStatus(props, event) {
      const app = this
      const changeSchoolStatusResource = new Resource('schools/toggle-school-non-payment-suspension')
      const param = { school_id: props.row.id, status: event }
      changeSchoolStatusResource.store(param)
        .then(response => {
          if (response === 'success') {
            app.schools[props.index - 1].suspended_for_nonpayment = event
          }
        }).catch(error => {
          app.$message(error.response.data.message)
        })
    },
  },
}
</script>
